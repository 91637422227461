import * as Yup from 'yup';
import InputMask from 'react-text-mask'
import axios from 'axios'
import {useFormik} from 'formik'
import { Link } from 'react-router-dom'
import error from '../css/formValidation.module.css'

export const formSchema = Yup.object().shape({
    name: Yup.string()
      .max(40, 'В поле должно быть не меньше 40 символов')
      .required('Обязательное поле'),
  
    phone: Yup.string()
      .transform(value => value.replace(/[^\d]/g, ''))
      .required('Обязательное поле')
      .min(11, 'Номер телефона должен быть введен полностью')
  });

const FeedbackForm = ({style, setSendError, openModals, setOpenModals}) => {
    const url = {
        domain: 'https://metrika.ferma-site.ru/index.php',
        args: 'module=API&method=Leads.addReport&format=json'    
    }
    const formik = useFormik({
        initialValues: {
            name: '',
            phone: ''
        },
        onSubmit: values => {
            axios({
                method: "get",
                url: `${url.domain}?${url.args}&who=${values.name}&phone=${values.phone}`,
            }).then(res => {
                setOpenModals({...openModals, form: false, response: true})
                if (res.status === 200) { 
                } else {
                    setSendError(true)
                }
            })
            .catch((e) => {
                setSendError(true)
            })
        },
        validationSchema: formSchema
    })
    return ( 
            <form onSubmit={formik.handleSubmit}>
                <h2>Получите медиаплан <br/>для продвижения бизнеса</h2>
                <div className={`${style.nameBlock} ${error.nameBlock}`}>
                    <input 
                        value={formik.values.name} 
                        onChange={formik.handleChange} 
                        type="text" name="name" id="name" 
                        placeholder="Представьтесь" 
                        className={formik.errors.name ? error.errorInput : ''}
                    />
                    {formik.errors.name ? (
                        <div className={error.error}><div className={error.errorTriangle}></div>{formik.errors.name}</div>
                    ) : null}
                </div>
                <div className={`${style.phoneBlock} ${error.phoneBlock}`}>
                    <InputMask 
                        mask={['+', '7', '(', /\d/, /\d/, /\d/, ')', '-',
                            /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/
                        ]} 
                        onChange={formik.handleChange} 
                        value={formik.values.phone} 
                        type="text" name="phone" id="phone"
                        placeholder="Номер вашего телефона" 
                        className={formik.errors.phone ? error.errorInput : ''}
                    />
                    {formik.errors.phone ? (
                        <div className={error.error}><div className={error.errorTriangle}></div>{formik.errors.phone}</div>
                    ) : null} 
                </div>    
                <button 
                    className={style.formButton} 
                    disabled={Object.keys(formik.errors).length!==0 || formik.values.name === '' || formik.values.phone === '' || openModals.response===true} 
                    id="formButton" type="submit"
                >Получить
                </button>
                <h3 onClick={() => setOpenModals({...openModals, form: false})} className={style.confidential}>Нажимая кнопку «Получить», вы соглашаетесь с <Link to="/privacy_policy">«Политикой конфиденциальности»</Link></h3>
            </form>)
}

export default FeedbackForm