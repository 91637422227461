import  {Routes, Route} from 'react-router-dom'
import IndexPage from './pages/IndexPage'
import PrivacyPage from './pages/PrivacyPage'
import MainLayout from './MainLayout'
import { useState } from 'react'

function App() {
  const [openModals, setOpenModals] = useState({
    form: false,
    response: false
  })
  const [sendError, setSendError] = useState(false)
  return (
    <MainLayout sendError={sendError} setSendError={setSendError} openModals={openModals} 
    setOpenModals={setOpenModals} >
      <Routes>
        <Route path="/" element={<IndexPage sendError={sendError} setSendError={setSendError} openModals={openModals} setOpenModals={setOpenModals} />} />
        <Route path="/privacy_policy" element={<PrivacyPage />} />
      </Routes>
    </MainLayout>
  )
}

export default App
