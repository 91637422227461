import FeedbackForm from '../components/FeedbackForm'
import { SuccessSentModal, ErrorSentModal } from '../components/ModalComponents'

import style from './indexPage.module.css'
import mesh from '../css/mesh.module.css'
import form from '../components/feedbackForm.module.css'
import error from '../css/formValidation.module.css'
import modal from '../components/feedbackModal.module.css'

const IndexPage = ({ sendError, setSendError, openModals, setOpenModals }) => {
    return (
        <>
            <div className={`${style.index} ${mesh.container} ${style.flex}`}>
                <div className={style.stretching}>
                    <div className={style.offer}>
                        <h1>Нужны заявки<br />
                            для стоматологии?
                        </h1>
                    </div>
                    <div className={style.getBlock}>
                        <h2 className={style.blockHeader}>
                            Приведём тёплых клиентов из Яндекс.Директ
                        </h2>
                        <div className={`${form.formBlock} ${error.formBlock}`}>
                            <FeedbackForm setOpenModals={setOpenModals} openModals={openModals} setSendError={setSendError} style={form} />

                        </div>
                        {openModals.response === true &&
                            <div className={modal.dimnessBlock}>
                                <div className={mesh.container}>
                                    {sendError === false ?
                                        <SuccessSentModal openModals={openModals} setOpenModals={setOpenModals} setSendError={setSendError} />
                                        : <ErrorSentModal openModals={openModals} setOpenModals={setOpenModals} setSendError={setSendError} />}
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default IndexPage
