import style from './privacyPolicy.module.css'
import mesh from '../css/mesh.module.css'

const PrivacyPage = () => {
    return (
        <div className={`${style.background} ${mesh.container}`}>
            <div className={style.confidence}>
                <h1>Политика конфиденциальности</h1>
                <ol>
                    <li>
                        <h2>Общие положения</h2>
                        <ol>
                            <li>
                                <p>Настоящая Политика конфиденциальности определяет общие условия сбора и обработки персональных
                                    данных пользователей сайта fermastudio.com, принадлежащего ООО «Ферма»
                                (далее — ФЕРМА).</p>
                            </li>
                            <li>
                                <p>ФЕРМА осуществляет обработку следующих персональных данных:</p>
                                <ul>
                                    <li>полное имя;</li>
                                    <li>адрес электронной почты;</li>
                                    <li>номер мобильного телефона;</li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Цели обработки персональных данных</h2>
                        <ol>
                            <li>
                                <p>Оператор ФЕРМА обрабатывает персональные данные пользователей с целью:</p>
                                <ul>
                                    <li>предоставления услуг;</li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Доступ третьих лиц к персональным данным</h2>
                        <ol>
                            <li>
                                <p>Оператор ФЕРМА может передать персональные данные пользователя третьей стороне 
                                    в следующих случаях:</p>
                                <ul>
                                    <li>если передача персональных данных необходима для услуги;</li>
                                    <li>если пользователь дал согласие на осуществление передачи своих данных третьей стороне;</li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2>Обязанности оператора ФЕРМА по защите персональных данных</h2>
                        <ol>
                            <li>
                                <p>Оператор ФЕРМА обязан принимать необходимые организационныеи технические меры для обеспечения конфиденциальности, целостностии доступности персональных данных пользователей.</p>
                            </li>
                            <li><p>
                                Оператор ФЕРМА обязан своевременно производить оценку соответствия требованиям законодательства РФ в области защиты информации.
                            </p></li>
                        </ol>
                    </li>
                    <li>
                        <h2>Обратная связь</h2>
                        <ol>
                            <li>
                                <p>Все предложения и вопросы по использованию сайта следует направлятьв службу поддержки
                                по адресу электронной почты <a href="mailto:support@ferma.agency">support@ferma.agency</a></p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div> 
        </div>
        
    )
}

export default PrivacyPage