import Header from './components/Header'
import {useLocation} from 'react-router-dom'
import {FeedbackModal, SuccessSentModal, ErrorSentModal} from './components/ModalComponents'

import './css/reset.css'
import mesh from './css/mesh.module.css'
import background from './css/indexBackground.module.css'
import header from './components/header.module.css'

const MainLayout = ({children, sendError, setOpenModals, openModals, setSendError}) => {
    const location = useLocation()
    return (
        <>
        <div className={`${mesh.page} ${background.index}`}> 
            <Header 
                color={location.pathname === '/' ? header.indexPageColor : header.privacyPageColor} 
                logoPaths={location.pathname === '/' ?
                {fermaLogo: 'index_ferma.svg', agencyLogo: 'index_agency.svg'}
                : {fermaLogo: 'privacy_ferma.svg', agencyLogo: 'privacy_agency.svg'}
                }
                openModals={openModals}
                setOpenModals={setOpenModals}
            />
                <main>
                    {children}
                </main>
        </div>
    {openModals.form === true && 
        <FeedbackModal setSendError={setSendError} openModals={openModals} setOpenModals={setOpenModals} />}
    {openModals.response === true && (
        sendError === false ? <SuccessSentModal setSendError={setSendError} openModals={openModals} setOpenModals={setOpenModals} /> 
        : <ErrorSentModal setSendError={setSendError} openModals={openModals} setOpenModals={setOpenModals} />
    )}
    </>)
}

export default MainLayout