import FeedbackForm from './FeedbackForm'

import mesh from '../css/mesh.module.css'
import modal from './feedbackModal.module.css'
import sent from './sentModal.module.css'
import close from '../css/closeButton.module.css'
import error from './sentModal.module.css'

export const ModalLayout = ({children, setSendError, openModals, setOpenModals, modalName, formClass}) => {
    return (
        <div className={modal.dimnessBlock}>
            <div className={mesh.container}>
                <div className={`${formClass} ${error.formBlock}`}>
                    <button className={close.closeButton} onClick={() => {
                        setOpenModals({...openModals, [modalName]: false})
                        setSendError(false)
                    }}>
                        <img src="images/cross.svg" alt="Закрыть окно" />
                    </button>
                    {children}
                </div>
                <div className={modal.closeBackground} onClick={() => {
                    setOpenModals({...openModals, [modalName]: false})
                    setSendError(false)
                }}></div>
            </div>
        </div>)
}
export const FeedbackModal = ({setSendError, openModals, setOpenModals}) => {
    return (
        <ModalLayout setSendError={setSendError} openModals={openModals} setOpenModals={setOpenModals}  modalName="form" formClass={modal.formBlock}>
            <FeedbackForm openModals={openModals} setOpenModals={setOpenModals} setSendError={setSendError} style={modal} />
        </ModalLayout>)
}

export const SuccessSentModal = ({setSendError, openModals, setOpenModals}) => {
    return (
        <ModalLayout setSendError={setSendError} openModals={openModals} setOpenModals={setOpenModals} modalName="response" formClass={sent.modal}>
            <div className={sent.imageBlock}>
                <img className={sent.circle} src="images/ok_circle.svg" alt="" />
                <img className={sent.bottomStar} src="images/star.svg" alt="" />
                <img className={sent.topStar} src="images/star.svg" alt="" />
                <img className={sent.bigStar} src="images/star.svg" alt="" />
            </div>
            <h2>
                Спасибо, что отправили заявку!<br/>
                Мы с вами свяжемся в ближайшее время
            </h2>
        </ModalLayout>)
}

export const ErrorSentModal = ({setSendError, openModals, setOpenModals}) => {
    return (
        <ModalLayout setSendError={setSendError} openModals setOpenModals={setOpenModals} modalName="response" formClass={sent.modal}>
            <h2>
                При отправке сообщения произошла ошибка! Попробуйте позже.
            </h2>
        </ModalLayout>)
}